import React, { Component } from "react";

import Stepper from "../common/stepper/Stepper";
import NewMailingAddress from "./UpdateMailingAddress";
import MailingSuccessView from "./ConfirmMailingAddress";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  addClientAlert,
  getAlertsByAccount
} from "./../../store/actions/alerts-actions";
import { getAccountInfo, setAccountDataRefresh } from "./../../store/actions/accounts-actions.js";
import "./UpdateMailingAddress.css";


class ChangeMailingAddress extends Component {
  constructor() {
    super();
    this.state = {
      formData: null,
      premiumUpdateVerifyData: null,
      responseData: null
    };
  }

  componentDidMount() {
    this.props.actions.getAccountInfo();
    window.scrollTo(0, 0)
  }  

  setResponseData = responseData => {
    this.setState({ responseData: responseData });
    this.props.actions.setAccountDataRefresh();
  };
//   setMailingData = (formData,responseData) => {
//   this.setState({
//   formData: formData,
//    responseData: responseData
//     });
//  };


  render() {
    return (
      <div style={{ position: this.props.canUserEdit ? "static" : "relative" }}>
        {this.props.canUserEdit ? null : <div className="disable-user-edit" />}
        <div className="removestepperheader">
          <Stepper>
            <Stepper.Step>
            <NewMailingAddress
            setMailingData={this.setMailingData}
              userAccountDetails={this.props.userAccountDetails}
              userAccountDetailsFetching={this.props.userAccountDetailsFetching}
              formData={this.state.formData}
              setResponseData={this.setResponseData} 
              backToHome={() => {
                this.props.history.push("/");
              }}/> 
              </Stepper.Step>
              <Stepper.Step> 
              <MailingSuccessView
              responseData={this.state.responseData}
              userDetails={this.props.userAccountDetails}
              backToHome={() => {
                this.props.history.push("/");
              }}
            />
            </Stepper.Step>
            </Stepper>
      </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  canUserEdit: state.account.canUserEdit,
  userAccountDetailsFetching: state.account.fetchingAccount,
  userAccountDetails: state.account.userAccountDetails,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    { getAccountInfo, setAccountDataRefresh },
    dispatch
  )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangeMailingAddress);
