import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import UpdateSuccessView from "../../common/update-success-view/UpdateSuccessView";
import UpdateReimbursementSuccessView from "./UpdateReimbursementSuccessView.component";
import {
  MailCheckView,
  DirectDepositView
} from "./UpdateReimbursementView.component";
import "./UpdateReimbursement.css";
import Loader from "../../common/loader/Loader";
import StateList from "../../common/data/StateList";
import FaqWidget from "../../common/faq/FaqWidget";
import { updateReimbursementFaqLinks } from "../../common/faq/FaqLink";
import { setReimbursement } from "../../../api/accounts-api";
import { setAccountDataRefresh, getAccountInfo } from "../../../store/actions/accounts-actions";

import ErrorView from "../../common/error-view/ErrorView";

import ModalDialog from "../../common/modal-dialog/ModalDialog";
import { AppLinks } from "../../../constants/app-links";

export const MAIL_CHECK = "MailCheck";
export const DIRECT_DEPOSIT = "DirectDeposit";

class UpdateReimbursement extends Component {
  constructor(props) {
    super(props);

    var updateMailing = false;

    if (props.location.search) {
      var input = props.location.search.trim().replace("?", "");

      if (input)
        for (const param of input.split("&")) {
          let [key, value] = param.split("=");
          if (key && value && key.toLowerCase() === "updatemailing" && value.toLowerCase() === "true") updateMailing = true;
        }
    }

    this.toggle = this.toggle.bind(this);
    this.errorText = null;
    this.state = {
      fetching: false,
      updateReimbursementSuccess: false,
      activeTab: updateMailing ? MAIL_CHECK : undefined,
      updateMailing: updateMailing,
      serverError: false,
      successValue: null,
      showSecurityDialog: false
    };

    //For google analytics
    window.dataLayer.push({
      'event': 'Pageview',
      'url': window.location.pathname
    });

  }

  toggleSecurityDialog = () => {
    this.setState({
      showSecurityDialog: !this.state.showSecurityDialog
    });
  };

  updateReimbursement = (type, values, accountType) => {
    this.setState({
      fetching: true
    });

    if (type === MAIL_CHECK && values.state === StateList.selectYourState) {
      this.errorText = "Please Select a Valid State";
      this.setState({
        serverError: true,
        fetching: false
      });
      window.scrollTo(0, 0);
      return;
    }

    if (
      type === DIRECT_DEPOSIT &&
      values.accountNumber !== values.verifyAccountNumber
    ) {
      this.errorText = "The account numbers do not match.";
      this.setState({
        serverError: true,
        fetching: false
      });
      window.scrollTo(0, 0);
      return;
    }

    var val = {};

    if (type === MAIL_CHECK) {
      val = {
        Street: values.streetAddress.trim(),
        City: values.city.trim(),
        State: values.state,
        PostalCode: values.zipCode
      };
    } else if (type === DIRECT_DEPOSIT) {
      val = {
        BankAccountType: accountType,
        PhoneNumber: values.primaryPhoneNumber.trim().replace(/\D/g, ""),
        RoutingNumber: values.routingNumber.trim(),
        AccountNumber: values.accountNumber.trim()
      };
    }

    setReimbursement(val, type === MAIL_CHECK)
      .then(response => {
        this.setState({
          updateReimbursementSuccess: true,
          fetching: false,
          successValue: val
        });

        if (this.state.updateMailing === true) {
          //For google analytics
          window.dataLayer.push({
            'event': 'Pageview',
            'url': window.location.pathname + '=Confirm'
          });
        } else {
          //For google analytics
          window.dataLayer.push({
            'event': 'Pageview',
            'url': window.location.pathname + '/Confirm'
          });
        }

        this.props.actions.setAccountDataRefresh();
        window.scrollTo(0, 0);
      })
      .catch(error => {
        this.errorText = (error && error.errorMessage) ? error.errorMessage : "Unable to update reimbursement detail. Please try later.";
        this.setState({
          fetching: false,
          serverError: true
        });
        window.scrollTo(0, 0);
      });
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.actions.getAccountInfo();
  }

  static getDerivedStateFromProps(props, state) {

    if (state.activeTab === undefined && !props.userAccountDetailsFetching && !state.updateMailing) {
      return {
        activeTab: props.userAccountDetails.ClaimReimbursementMethod === "Check" ? MAIL_CHECK : DIRECT_DEPOSIT,
      };
    }
    return null;
  }

  toggle = value => {
    this.setState({
      activeTab: value
    });
  };

  render() {
    return (
      <React.Fragment>
        {this.securityDialog()}
        {this.state.updateReimbursementSuccess ? (
          <React.Fragment>
            <div className="d-lg-none">
              <UpdateSuccessView
                statusHeader="Your reimbursement information has been updated."
                title="Your reimbursement information has been updated."
              >
                {this.successView()}
              </UpdateSuccessView>
            </div>
            <div className="d-none d-lg-block">
              <UpdateSuccessView
                statusHeader="Your reimbursement information has been updated."
                title="Your reimbursement information has been updated."
              >
                {this.successView()}
              </UpdateSuccessView>
            </div>
          </React.Fragment>
        ) : (
            <div className="d-flex flex-column container reimbursement-container margin-bottom">
              <h1 className="title-container d-flex align-items-center">
                Change Reimbursement Info
            </h1>
              {this.state.serverError && <ErrorView>{this.errorText}</ErrorView>}
              {!this.props.userAccountDetailsFetching &&
                <div className="row ">
                  <div className="d-flex flex-column margin-bottom col-12 col-lg-8">
                    <div className="edit-reimbrusement-container">
                      {!this.state.updateMailing &&
                        <React.Fragment>
                          <h4 className="select-type">
                            1. Select Your Reimbursement Type
                  </h4>
                          <div className="row tab-parent">
                            <label
                              onClick={() => {
                                this.toggle(MAIL_CHECK);
                              }}
                              className={
                                "col-6 d-flex align-items-center justify-content-center left-tab " +
                                (this.state.activeTab === MAIL_CHECK
                                  ? "selected-tab"
                                  : "unselected-tab")
                              }
                            >
                              Mail Check
                    </label>
                            <label
                              onClick={() => {
                                this.toggle(DIRECT_DEPOSIT);
                              }}
                              className={
                                "col-6 d-flex align-items-center justify-content-center right-tab " +
                                (this.state.activeTab === DIRECT_DEPOSIT
                                  ? "selected-tab"
                                  : "unselected-tab")
                              }
                            >
                              Direct Deposit
                    </label>
                          </div>
                          <hr />
                        </React.Fragment>
                      }
                      {(this.state.activeTab === MAIL_CHECK || this.state.updateMailing) ? (
                        <MailCheckView
                          updateReimbursement={this.updateReimbursement.bind(this)}
                          userAccountDetails={this.props.userAccountDetails}
                          userAccountDetailsFetching={this.props.userAccountDetailsFetching}
                          updateMailing={this.state.updateMailing}
                          backToHome={() => {
                            this.props.history.push("/");
                          }}
                        />
                      ) : (
                          <DirectDepositView
                            toggleSecurityDialog={this.toggleSecurityDialog}
                            updateReimbursement={this.updateReimbursement.bind(this)}
                            userAccountDetails={this.props.userAccountDetails}
                            userAccountDetailsFetching={this.props.userAccountDetailsFetching}
                            backToHome={() => {
                              this.props.history.push("/");
                            }}
                          />
                        )}
                      <hr />
                      <label className="billing-notes">
                        Note: Updating your mailing address does not update your
                        physical or billing address. If you've moved and want to
                        update your physical address, please <Link to={AppLinks.account.changePhysicalAddress}> click here </Link>.
                  </label>
                    </div>
                  </div>

                </div>
              }
              {(this.state.fetching || this.props.userAccountDetailsFetching) && <Loader position="absolute" fullPage={true} />}
            </div>
          )}
      </React.Fragment>
    );
  }

  successView = () => {
    return (
      <UpdateReimbursementSuccessView
        value={this.state.successValue}
        activeTab={this.state.activeTab}
        backToHome={() => {
          this.props.history.push("/");
        }}
      />
    );
  };

  securityDialog = () => {
    return (
      <ModalDialog
        isOpen={this.state.showSecurityDialog}
        toggle={this.toggleSecurityDialog}
        modalHeader={"Bank account information"}
        modalBody={
          <div className="d-flex flex-column">
            <img
              alt="Bank Account Info"
              className="account-info-image"
              src="../../assets/images/bank_account_info.png"
            />
            <h4 className="account-info-heading-text">Routing number</h4>
            The first set of numbers on the lower left corner of a check is the
            routing number. Keep in mind, the routing numbers are 9-digit codes
            and the character symbols surrounding the numbers are not part of
            the number on the check.
            <h4 className="account-info-margin account-info-heading-text">
              Account number
            </h4>
            <p>The second set of numbers following the character symbol immediately
            after the routing number is the account number. The check account
            number is private and unique to the bank account.</p>

            <p>Please note that your bank may use a different routing number for ACH
            (Automated Clearing House) transfers than the routing number on your checks,
            and/or require the zeros at the beginning of your account number to be included.
            Please confirm both the correct ACH routing number and the full account number
            with your bank prior to enrolling.</p>
          </div>
        }
        modalFooter={
          <button
            className="button-primary-plus close-button"
            type="submit"
            onClick={this.toggleSecurityDialog}
          >
            Close Window
          </button>
        }
      />
    );
  };
}

const mapStateToProps = state => ({
  userAccountDetails: state.account.userAccountDetails,
  userAccountDetailsFetching: state.account.fetching,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ getAccountInfo, setAccountDataRefresh }, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UpdateReimbursement);
