import { AppLinks } from '../../../constants/app-links';

export const mobileMenuItems = [
   {
       header: "Home",
       identifier: "home",
       link: "/",
       icon: true
   },
   {
       header: "Claims",
       identifier: "claims",
       menu_items: [
           { subtitle: "File a Claim", link: AppLinks.claims.fileClaim },
           { subtitle: "View Claim Status", link: AppLinks.claims.claimStatus },
           { subtitle: "Upload Medical Records", link: AppLinks.claims.uploadMedicalRecords },
           { subtitle: "Get the Mobile App", link: AppLinks.claims.getMobileApp }
       ]
   },
   {
       header: "My Account",
       identifier: "my-account",
       menu_items: [
           { subtitle: "View Your Profile", link: AppLinks.account.viewProfile },
           { subtitle: "Change Physical Address", link: AppLinks.account.changePhysicalAddress },
           { subtitle: "Change Mailing Address", link: AppLinks.account.updateMailingAddress },
           { subtitle: "Change Profile", link: AppLinks.account.changeProfile },
           { subtitle: "Change Password", link: AppLinks.account.changePassword},
           { subtitle: "Update Billing Info", link: AppLinks.account.updateBillingInfo },
           { subtitle: "Change Reimbursement Info", link: AppLinks.account.updateReimbursement }
       ]
   },
   {
       header: "Pet Policy",
       identifier: "pet-policies",
       menu_items: [
           { subtitle: "View Pet Details", link: AppLinks.policy.viewPetDetails },
           { subtitle: "Add a Pet", link: AppLinks.policy.addPet },
           { subtitle: "View Policy Options", link: AppLinks.policy.changePolicyOptions },
           { subtitle: "View Policy Documents", link: AppLinks.policy.viewPolicyDocuments }
       ]
   },
//    {
//        header: "Refer a Friend",
//        identifier: "refer-friend",
//        link: "#",
//        menu_items: [
//            { subtitle: "Refer a Friend", link: "#" }
//        ]
//    },
   {
       header: "Support",
       identifier: "support",
       link: "#",
       mobile: true,
       menu_items: [
           { subtitle: "Frequently Asked Questions", link: AppLinks.faq.faq },
           { subtitle: "Contact Us", link: AppLinks.others.contact },
           { subtitle: "Privacy Policy", link: AppLinks.externalLinks.privacyPolicy, external:true },
           { subtitle: "CA Privacy Policy", link: AppLinks.externalLinks.caPrivacyPolicy, external: true },
           { subtitle: "Terms and Conditions", link: AppLinks.externalLinks.legal, external: true },
       ]
   },
   {
       header: "Sign Out",
       identifier: "signout",
       link: "#",
       mobile: true,
   }
];

export const menuItems = [
   {
       header: "Home",
       identifier: "menu-home",
       link: "/",
       icon: false
   },
   {
       header: "Pet Policies",
       identifier: "menu-policy",
       menu_items: [
           { subtitle: "View Pet Details", link: AppLinks.policy.viewPetDetails },
           { subtitle: "Add a Pet", link: AppLinks.policy.addPet },
           { subtitle: "View Policy Options", link: AppLinks.policy.changePolicyOptions },
           { subtitle: "View Policy Documents", link: AppLinks.policy.viewPolicyDocuments }
       ]
   },
   {
       header: "My Account",
       identifier: "menu-account",
       menu_items: [
           { subtitle: "View Your Profile", link: AppLinks.account.viewProfile },
           { subtitle: "Change Physical Address", link: AppLinks.account.changePhysicalAddress },
           { subtitle: "Change Mailing Address", link: AppLinks.account.updateMailingAddress },
           { subtitle: "Change Profile", link: AppLinks.account.changeProfile },
           { subtitle: "Change Password", link: AppLinks.account.changePassword},
           { subtitle: "Update Billing Info", link: AppLinks.account.updateBillingInfo },
           { subtitle: "Change Reimbursement Info", link: AppLinks.account.updateReimbursement }
       ]
   },
   {
       header: "Claims",
       identifier: "menu-claims",
       menu_items: [
           { subtitle: "File a Claim", link: AppLinks.claims.fileClaim },
           { subtitle: "View Claim Status", link: AppLinks.claims.claimStatus },
           { subtitle: "Upload Medical Records", link: AppLinks.claims.uploadMedicalRecords },
           { subtitle: "Get the Mobile App", link: AppLinks.claims.getMobileApp }
       ]
   },
   {
       header: "FAQ",
       identifier: "menu-faq",
       menu_items: [
           { subtitle: "Plan Coverage", link: AppLinks.faq.planCoverageFAQ },
           { subtitle: "Policy Management", link: AppLinks.faq.policyManagementFAQ },
           { subtitle: "Submitting Claims", link: AppLinks.faq.submittingClaimsFAQ }
        //    { subtitle: "Refer a Friend", link: AppLinks.faq.referAFriendFAQ }
       ]
   },
//    {
//        header: "Refer a Friend",
//        identifier: "menu-refer-friend",
//        link: "#",
//        menu_items: [
//            { subtitle: "Refer a Friend", link: "#" }
//        ]
//    },
   {
       header: "Support",
       identifier: "menu-support",
       link: "#",
       mobile: true,
       menu_items: [
           { subtitle: "Frequent Questions", link: AppLinks.faq.faq },
           { subtitle: "Contact Us", link: AppLinks.others.contact },
       ]
   },
   {
       header: "Sign Out",
       identifier: "signout",
       link: "#",
       mobile: true,
   }
];
