import React, { Component } from "react";

import { Form } from "informed";
import EditText from "../common/form-fields/edit-text/EditText";
import FaqWidget from "../common/faq/FaqWidget";
import ErrorView from "../common/error-view/ErrorView";
import Loader from "../common/loader/Loader";
import StateList from "../common/data/StateList";
import DropDownSelect from "../common/form-fields/drop-down/DropDownSelect";
import "./UpdateMailingAddress.scss";
import { AppLinks } from "../../constants/app-links";
import { Link } from "react-router-dom";

import { updateMailingAddress, validateZipcode } from "../../api/accounts-api";

import MaskValidateUtil from "../common/util/MaskValidateUtil";

import { updateUpdateMailingAddressFaqLinks } from "../common/faq/FaqLink";

export default class NewMailingAddress extends Component {
    constructor() {
      super();
      this.errorText = null;
      this.state = {
        sendingDataToServer: false,
        serverError: false,
        formApi: null
      };
    }
    
    validateAndUpdateMailingAddress = values => {

        // Need to check against PO Boxes
        var hasError = false;
        
        var POBoxPattern = new RegExp('\\b[p]*(ost)*\\.*\\s*[o|0]*(ffice)*\\.*\\s*b[o|0]x\\b', 'i');
    
        if (values.streetAddress.match(POBoxPattern)) {
    
          this.state.formApi.setError(
            "streetAddress",
            "No PO Boxes allowed"
          );
    
          hasError = true;
        }
        
    
        if (hasError === false) {
          this.setState({
            sendingDataToServer: true
          });
          validateZipcode(values.zipCode).then(response => {
            if (response) {
              this.updateMailingAddress(values);
            } else {
              this.setState({
                sendingDataToServer: false
              });
    
              this.state.formApi.setError(
                "zipCode",
                "Please enter a valid zip code."
              );
            }
          }).catch(error => {
            this.updateMailingAddress(values);
          })
        }
      };
      updateMailingAddress = values => {
        this.setState({
          sendingDataToServer: true,
          serverError: false
        });
    
        var mailingaddressInfo = {
          address1: values.streetAddress,
          city_name: values.city,
          state_name: values.state,
          postal_code: values.zipCode,
          use_for_mailing_address: true
        };
        updateMailingAddress(mailingaddressInfo)
        .then(response => {
         // this.props.setMailingData(mailingaddressInfo);
         this.setState({
        sendingDataToServer: false
          });
          this.props.stepper.nextStep();

        this.props.setResponseData(mailingaddressInfo);
    })
}
    
      setFormApi = api => {
        this.setState({
          formApi: api
        });
      };
    
      fetchInitialValues = (userData) => {
    
        if (userData && userData.MailingAddress) {
          var responseData = userData.MailingAddress;
          return {
            streetAddress: responseData.Street,
            city: responseData.City,
            state: responseData.State,
            zipCode: responseData.PostalCode,
          };
        }
    
        return {};
      }
    
    render() {
        return (
             <div className="new-address-container container">
                {!this.props.userAccountDetailsFetching &&
                <Form
                onSubmit={values => {
                  this.validateAndUpdateMailingAddress(values);
                }}
                getApi={api => {
                    this.setFormApi(api);
                  }}
                  initialValues={this.fetchInitialValues(this.props.formData ? this.props.formData : this.props.userAccountDetails)}
                >
                {({ formState }) => (
                    <React.Fragment>
                        <div className="row">
                            <div className="col-12 col-lg-8">
                                <h1 className="title-container d-flex align-items-center">
                                    Change Mailing Address
                                </h1>
                                {this.state.serverError && (
                                    <ErrorView>{this.errorText}</ErrorView>
                                )}
                                <p className="mb-0">
                                    Please review your current mailing address and update it if necessary. Any customer-related mails  will be sent to the mailing address on your account.
                                </p>
                                    <div>
                                        <div className="GreyBox">
                                            <div className="greyBoxtext">
                                                <strong>*Keep in mind</strong>
                                                <ul className="greyboxpoints">
                                                    <li>
                                                    Direct deposit: If you have recently signed up to receive claim reimbursements via direct deposit, please ensure that your mailing address is correct. We will issue claim reimbursement checks via mail until the direct deposit processing period is complete (5-10 business days).
                                                    </li>
                                                    <li>
                                                    Updating your mailing address does not update your physical address (Residence). If you want to update your physical address, please visit <Link style={{textDecoration: "none"}} to={AppLinks.account.changePhysicalAddress} >Change Physical Address</Link> page.
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div>
                                            <p>
                                                <strong>
                                                    Enter New Mailing Address
                                                </strong>
                                            </p>
                                            <EditText
                                                required
                                                className="street-address-container margin-bottom"
                                                id="street_address"
                                                field="streetAddress"
                                                type="text"
                                                title="New Street Address"
                                                floatingTitle={true}
                                            />

                                            <EditText
                                                required
                                                className="address-container margin-bottom"
                                                id="city"
                                                field="city"
                                                type="text"
                                                title="City"
                                                floatingTitle={true}
                                            />

                                            <DropDownSelect
                                                style={{ width: "100%" }}
                                                field="state"
                                                className="d-md-none address-container margin-bottom"
                                                id="select_state"
                                                data={StateList.getStateList()}
                                            />

                                            <DropDownSelect
                                                style={{ width: "269px" }}
                                                field="state"
                                                className="d-none d-md-block address-container margin-bottom"
                                                id="select_state"
                                                data={StateList.getStateList()}
                                            />

                                            <EditText
                                                required
                                                className="address-container margin-bottom"
                                                id="zip_code"
                                                field="zipCode"
                                                type="text"
                                                title="New Zip Code"
                                                floatingTitle={true}
                                                mask={MaskValidateUtil.zipCodeMask}
                                                validate={MaskValidateUtil.mailZipCodeValidate}
                                                validateOnBlur
                                                validateOnChange={formState.errors.zipCode}
                                            />
                                            <div className="w-100 d-flex flex-column flex-md-row">
                                                <button
                                                    className="button-primary button-mc-container margin-bottom"
                                                    type="submit"
                                                    disabled={this.props.fetching || formState.invalid}
                                                >
                                                    Save Changes
                                                </button>
                                                <button
                                                style={{fontWeight: '700', color: '#6b61f2'}}
                                                    className="button-link cancel-button margin-bottom"
                                                    type="button"
                                                    onClick={() => window.location.href = "/"}
                                                >
                                                    Cancel
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                            </div>
                            <div className="col-lg-4 mt-3 mb-3 faqwidget">
                                <FaqWidget
                                    title="Mailing Address FAQs"
                                    mobileTitle="FAQs About Mailing Address Change"
                                    faqLink={updateUpdateMailingAddressFaqLinks}
                                />
                            </div>
                        </div>
                    
                  </React.Fragment>
                )}
              </Form>
            }
            {(this.state.sendingDataToServer || this.props.userAccountDetailsFetching) && <Loader position="absolute" fullPage={true} />}
          </div>
        );
      }
    }    

