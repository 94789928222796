import React from "react";
import "./UpdateReimbursementSuccessView.component.css";

import MaskValidateUtil from "../../common/util/MaskValidateUtil";

import { DIRECT_DEPOSIT, MAIL_CHECK } from "./UpdateReimbursement";
import { CHECKING } from "./UpdateReimbursementView.component";

const UpdateReimbursementSuccessView = props => {
  return (
    <div className="d-flex flex-column">
      {
        (props.activeTab === DIRECT_DEPOSIT) ?
        <div className="pt-2">
          <p className="status-description">
          Please note that <strong>the direct deposit setup process takes 5-10 business days.</strong> You will receive a confirmation email once the process has completed. Claim reimbursements issued in the meantime will be mailed to your mailing address on file.
          </p>
        </div>:
      
      <span className="status-description">
        Please review your new claim reimbursement information.
      </span>
}

      <div className="d-flex flex-column success-container padding mt-3">     
        {props.activeTab === MAIL_CHECK && (
          <React.Fragment>
            <h4>Reimbursement Type</h4>
            <span className="margin-bottom">
              Mail Check*
            </span>
            <h4>Mailing Address</h4>
            <span className="margin-bottom">
              {props.value.Street}
              <br />
              {props.value.City +
                ", " +
                props.value.State +
                " " +
                props.value.PostalCode}
            </span>
            <p style={{fontSize: "13px"}}>
            <strong>*Keep in mind</strong><br/>
            <span style={{fontSize: "13px", fontWeight: "400"}}>All claim reimbursement checks will be mailed to your new mailing address.</span>
            </p>
          </React.Fragment>
        )}

        {props.activeTab === DIRECT_DEPOSIT && (
          <React.Fragment>
            <h4>Account Type</h4>
            <span className="margin-bottom">
              {props.value.BankAccountType === CHECKING
                ? "Checking Account"
                : "Savings Account"}
            </span>

            <h4>Account Number</h4>
            <span className="margin-bottom">{props.value.AccountNumber.replace(/\d(?=\d{4})/g, "X")}</span>
            <p style={{fontSize: "13px"}}>
              <strong>*Keep in mind</strong><br/>
              <span style={{fontSize: "13px", fontWeight: "400"}}> The direct deposit setup process takes 5-10 business days, and you will receive a confirmation email once the process has completed. Once you have received that confirmation, future claim reimbursements will be deposited directly into your bank account.</span>
            </p>
          </React.Fragment>
        )}
{/* 
        <h4 className="keep-in-mind">*Keep in mind</h4>
        <span className="keep-in-mind">
          {props.activeTab === DIRECT_DEPOSIT &&
            <React.Fragment>
              All open claims that result in reimbursement will be deposited directly into your account.
            </React.Fragment>
          }
          {props.activeTab === MAIL_CHECK &&
            <React.Fragment>
              All open claims that result in reimbursement will be deliver to your new mailing address.
            </React.Fragment>
          }
        </span> */}
      </div>
      <button
        className="button-secondary update-success-button"
        type="submit"
        onClick={props.backToHome}
      >
        Home
      </button>
    </div>
  );
};

export default UpdateReimbursementSuccessView;
