import React, { Component } from "react";
import {
  isTablet,
  isIOS,
  isChrome,
  isMobile,
  isIE,
  isEdge
} from "react-device-detect";
import { getPolicyDoc } from "../../../api/policy-docs-api";

import Loader from "../loader/Loader";
import OopsLostPage from "../../oops/OopsLostPage";

class SalesForceLink extends Component {
  constructor() {
    super();
    this.state = {
      invalidLink: false,
      fetchingDoc: false
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    this.setState({
      fetchingDoc: true
    });
    
    let docType;
    if(this.props.match.params.type){
      docType = this.props.match.params.type;
    }else{
      if(this.props.location.pathname.indexOf('/eob/') !== -1){
        docType = 'eob';
      }      
    }
    
    getPolicyDoc(this.props.match.params.id, docType)
        .then(data => {             
        this.showDoc(data);
        this.setState({fetchingDoc:false})
      })
      .catch(err => {
        this.setState({
          invalidLink: true,
          fetchingDoc: false
        });
      });
  }

  renderMessageForIE = () => {
    return (
      <div className="container">
        <h1>Please wait, Your file is being downloaded.</h1>
      </div>
    );
  };

  showDoc = data => {
    if (
      (data.policy_documents && data.policy_documents.length === 0) ||
      (data.policy_documents[0].file_type !== "application/pdf" &&
        data.policy_documents[0].file_type !== "text/html")
    ) {
      this.setState({
        invalidLink: true
      });
    }
    if (
      data.policy_documents &&
      data.policy_documents.length > 0 &&
      data.policy_documents[0].file_type === "application/pdf"
    ) {
      var file = this.converBase64toBlob(
        data.policy_documents[0].file_data,
        "application/pdf"
      );
      //For IOS Mobile Viewing Blob has issue
      //So we are using fileReader API to show PDF
      if ((isTablet || isMobile) && isChrome && isIOS) {
        var reader = new FileReader();
        var out = file;
        reader.onloadend = function() {
          window.location.href = reader.result;
        };
        reader.readAsDataURL(out);
      } else if (isIE) {
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(file, this.props.match.params.id + ".pdf");          
        }
      } else {
        var fileUrl = URL.createObjectURL(file);
        var link = document.createElement("a");
        link.href = fileUrl;
        link.click();
        window.open(fileUrl);
        setTimeout(function() {
          //     document.body.removeChild(link)
          window.URL.revokeObjectURL(fileUrl);
        }, 100);
      }
    }
    if (
      data.policy_documents &&
      data.policy_documents.length > 0 &&
      data.policy_documents[0].file_type === "text/html"
    ) {
      var decodedString = window.atob(data.policy_documents[0].file_data);
      document.write(decodedString);
    }
  };

  converBase64toBlob = (content, contentType) => {
    contentType = contentType || "";
    var sliceSize = 512;
    var byteCharacters = window.atob(content); //method which converts base64 to binary
    var byteArrays = [];
    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);
      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      var byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    var blob = new Blob(byteArrays, {
      type: contentType
    }); //statement which creates the blob
    return blob;
  };

  render() {
    var componentToRender = null;
    if (this.state.fetchingDoc) {
      componentToRender = (
        <Loader position="absolute" zIndex={1000} className="op1" />
      );
    } else {
      if ((isIE || isEdge) && !this.state.invalidLink) {
        componentToRender = this.renderMessageForIE();
      } else {
          
        componentToRender = <OopsLostPage />;
      }
    }
    return <React.Fragment>{componentToRender}</React.Fragment>;
  }
}

export default SalesForceLink;
