import React, { Component } from "react";

import UpdateSuccessView from "../common/update-success-view/UpdateSuccessView";
import ReferFriend from "../common/refer-friend/ReferFriend";

export default class MailingSuccessView extends Component {


  render() {
    const {userDetails} = this.props;
    const {responseData}  = this.props;
    
    return (
        
      <div className="success-view mailingsuccess">
          <UpdateSuccessView
            statusHeader="Your mailing address has been successfully changed."
            title="Your mailing address has been updated."
          >
            <div className="container pl-0">
                <div className="col-md-12 mailingbody">
                  <span className="status-description">
                    Please review your mailing address.
                  </span>
                  <div className="d-flex flex-column success-container padding">
                    <h4 style={{ textTransform: "none", fontSize:"15px", fontWeight:"800", color:"#333333"}}>Mailing Address*</h4>
                    {responseData!=null || responseData!= undefined ?<div>
                        <span style={{fontWeight:"400"}}>{responseData.address1}</span>
                        <span className="d-block margin-bottom" style={{fontWeight:"400"}}>
                        {responseData.city_name+", "+responseData.state_name +
                            " " +responseData.postal_code}
                            </span>
                            </div>:null}
                     <span>
                        <p>
                        <strong style={{fontSize:"13px"}}>*Keep in mind</strong></p>
                        {userDetails.ClaimReimbursementMethod == "Check" ?
                        <p className="MailorDirect" style={{fontSize:"13px", fontWeight:"400"}}>All claim reimbursement checks will be mailed to your new mailing address.</p>:
                        <p className="MailorDirect">You are in the Direct Deposit processing period (takes 5-10 business days) because you can't use the Direct Deposit service yet. We’ll issue a reimbursement check(s) during this period. Once you have received that Direct Deposit confirmation, future claim reimbursements will be deposited directly into your bank account.</p>
                    }
                    </span> 
                  </div>
                  <button
                    className="button-secondary update-success-button margin-bottom"
                    onClick={this.props.backToHome}
                  >
                    Home
                  </button>
                </div>
              </div>
          </UpdateSuccessView>
      </div>
      
    );
  }
}
